let checkSelectvalidate = function(select) {
    let error = "error" + select.getAttribute("id");
    if (document.querySelector("#" + error)) { document.querySelector("#" + error).remove(); }
    if (select.value != "0") {
        select.parentNode.classList.add("is--valid");
        select.parentNode.classList.remove("is--invalid");
        select.removeAttribute("aria-describedby");
    } else {
        select.parentNode.classList.remove("is--valid");
        select.parentNode.classList.add("is--invalid");
        select.setAttribute("aria-describedby", error);
        let elError = document.createElement("span");
        elError.setAttribute("id", error);
        elError.classList.add("form__input--error");
        elError.innerHTML = "Wybierz jedną z opcji";
        select.parentNode.after(elError);
    }
}
console.log("pppp");

let checkInputValidate = function(input) {

    let error = "error" + input.getAttribute("id");
    if (input.closest(".form__input").querySelector(".form__input--error")) { input.closest(".form__input").querySelector(".form__input--error").remove(); }
    if (input.validity.valid) {
        input.closest(".form__input").classList.add("is--valid");
        input.closest(".form__input").classList.remove("is--invalid");

        input.removeAttribute("aria-describedby");
    } else {
        input.closest(".form__input").classList.remove("is--valid");
        if (input.form.classList.contains("afterFirstValidate")) {
            input.closest(".form__input").classList.add("is--invalid");
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.closest(".form__input").append(elError);
        }
    }


    console.log(input.value);
}


let checkBoxValidate = function(input) {
    let error = "error" + input.getAttribute("id");
    if (input.form.classList.contains("afterFirstValidate")) {
        if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
        if (input.validity.valid) {
            input.parentNode.classList.add("is--valid");
            input.parentNode.classList.remove("is--invalid")
            input.removeAttribute("aria-describedby");
        } else {
            input.parentNode.classList.remove("is--valid");
            input.parentNode.classList.add("is--invalid")
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.prepend(elError);
        }
    }
}

let isEmpty = function(el) {
    if (el.value == "") {
        el.parentNode.classList.add("empty");
        el.parentNode.classList.remove("noempty");
    } else {
        el.parentNode.classList.remove("empty")
        el.parentNode.classList.add("noempty")
        if (!el.validity.valid) {
            el.parentNode.classList.add("noempty");
            el.parentNode.classList.remove("empty");
        }
    }
}


if (document.querySelector('select:required')) {
    let select = document.querySelectorAll("select:required");
    for (let i = 0; i < select.length; ++i) {
        select[i].addEventListener('change', function(e) {
            (this.value == "0") ? this.classList.remove("js-selected"): this.classList.add("js-selected");
            checkSelectvalidate(this);

        });
    }
}

let inputsRequiredAll = document.querySelectorAll('.form__input textarea:required, .form__input input:required');
let inputsAll = document.querySelectorAll('.form__input textarea, .form__input input');
let inputsCheckBoxAll = document.querySelectorAll('.form__input textarea, .form__checkbox input');

if (inputsRequiredAll) {
    for (let i = 0; i < inputsRequiredAll.length; ++i) {
        inputsRequiredAll[i].addEventListener("input", function() {
            checkInputValidate(this);
        })
    }
}
if (inputsAll) {
    for (let i = 0; i < inputsAll.length; ++i) {
        inputsAll[i].addEventListener("input", function() {
            isEmpty(this)
        });
    }
}

if (inputsCheckBoxAll) {
    for (let i = 0; i < inputsCheckBoxAll.length; ++i) {
        inputsCheckBoxAll[i].addEventListener("input", function() {
            checkBoxValidate(this);
        });
    }
}

let formValidate = function(form, namefunction) {

    const $form = form;
    const $btnSubmitt = form.querySelector('button[type="submit"]');

    $btnSubmitt.addEventListener('click', function(e) {
        const inputsRequired = form.querySelectorAll(".form__input input:required, .form__input textarea:required");

        const checkBoxRequired = form.querySelectorAll(".form__checkbox input:required");
        const selectRequired = form.querySelectorAll(".form__select select:required");

        e.preventDefault();
        $form.classList.add("afterFirstValidate");

        for (let i = 0; i < inputsRequired.length; ++i) {
            checkInputValidate(inputsRequired[i]);

        }
        for (let i = 0; i < checkBoxRequired.length; ++i) {
            checkBoxValidate(checkBoxRequired[i]);
        }
        for (let i = 0; i < selectRequired.length; ++i) {
            checkSelectvalidate(selectRequired[i]);
        }

        if (form.querySelectorAll(".is--invalid").length == 0) {

            if (namefunction == 1) {
                if (document.querySelector("#del-pay-question input[data-rodzaj='9999']") || document.querySelector("#del-pay-question input[data-rodzaj='7777']")) {
                    if ($("#del-pay-question input[data-rodzaj='9999']").prop("checked") && !$("#input-paczkomat").val().length) {
                        $("#paczkomat-box button").click();
                    } else {
                        if ($("#del-pay-question input[data-rodzaj='7777']").prop("checked") && !$("#input-ruch").val().length) {
                            $("#ruch-box button").click();

                        } else {
                            form.submit();
                        }
                    }
                } else {
                    form.submit();
                }

            } else {
                e.preventDefault();
                namefunction(form);
            }

        } else {

            if (form.querySelector(".is--invalid")) {
                console.log(form.querySelector(".is--invalid"));
                let firstInvalidEl = form.querySelector(".is--invalid");
                if (firstInvalidEl.querySelector("input")) {
                    firstInvalidEl.querySelector("input").focus();
                } else {
                    if (firstInvalidEl.querySelector("textarea")) {
                        firstInvalidEl.querySelector("textarea").focus();
                    } else {
                        if (firstInvalidEl.querySelector("select")) {
                            firstInvalidEl.querySelector("select").focus();
                        }

                    }
                }

            }

        }
    })
}


if (document.querySelector('.form--validate')) {
    let formToValidate = document.querySelectorAll(".form--validate");
    for (let i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], 1);
    }
}


if (document.querySelector('.select-onsubmit')) {
    let selectOS = document.querySelectorAll(".select-onsubmit");
    for (let i = 0; i < selectOS.length; ++i) {
        selectOS[i].addEventListener('change', function(e) {
            this.form.submit();
        });
    }
}





if (document.getElementById('i_kod_pocztowy')) {
    var regExpMask2 = IMask(
        document.getElementById('i_kod_pocztowy'), {
            mask: '00-000'
        });
};
if (document.getElementById('wysylka_kod_pocztowy')) {
    var regExpMask3 = IMask(
        document.getElementById('wysylka_kod_pocztowy'), {
            mask: '00-000'
        });
};

// if (document.getElementById('i_telefon')) {

//     var regExpMask4 = IMask(
//         document.getElementById('i_telefon'), {
//             mask: '000 000 000'
//         });
// };
// if (document.getElementById('wysylka_telefon')) {
//     console.log(document.getElementById('wysylka_telefon'));
//     var regExpMask5 = IMask(
//         document.getElementById('wysylka_telefon'), {
//             mask: '000 000 000'
//         });
// };
if (document.getElementById('i_nip')) {
    var regExpMask6 = IMask(
        document.getElementById('i_nip'), {
            mask: Number
        });
};
if (document.getElementById('nip')) {
    var regExpMask7 = IMask(
        document.getElementById('nip'), {
            mask: Number
        });
};



// if ($("textarea").length) {
//     let textarea = document.querySelector('textarea');

//     textarea.addEventListener('keydown', autosize);

//     function autosize() {
//         var el = this;
//         setTimeout(function() {
//             el.style.cssText = 'height:auto; padding:4px';
//             // for box-sizing other than "content-box" use:
//             // el.style.cssText = '-moz-box-sizing:content-box';
//             el.style.cssText = 'height:' + el.scrollHeight + 'px';
//         }, 0);
//     }
// }



function removeAttributes(element, ...attrs) {
    attrs.forEach(attr => { element.removeAttribute(attr) })
}

function setAttributes(el, attrs) {
    for (var key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}


//checkbox firma czy osoba prywatna
if (document.querySelector('.cart__customer')) {
    let cartCustomerInputs = document.querySelectorAll(".cart__customer input");
    let formCInputs = document.querySelectorAll(".form__c input");
    for (let i = 0; i < cartCustomerInputs.length; ++i) {
        cartCustomerInputs[i].addEventListener("change", function() {
            console.log("klik");
            if (this.getAttribute("id") == "customer-1") {
                removeAttributes(document.querySelector("#i_nip"), "pattern", "minlength");
                if (document.querySelector('#f-wysylka-nip')) {
                    removeAttributes(document.querySelector("#f-wysylka-nip"), "pattern", "minlength");
                }
                formCInputs.forEach(attr => {
                    attr.removeAttribute("required");
                    if (attr.closest(".is--invalid")) {
                        attr.closest(".is--invalid").classList.remove("is--invalid");
                        attr.removeAttribute("aria-describedby")
                        attr.closest(".form__input").querySelector(".form__input--error").remove();
                    }
                    attr.parentNode.style.display = "none";
                })
            } else {
                formCInputs.forEach(attr => {
                    attr.setAttribute("required", "required");
                    attr.parentNode.style.display = "block";
                })
                setAttributes(document.querySelector("#i_nip"), { "minlength": "9", "pattern": "[0-9]+" })
                if (document.querySelector('#f-wysylka-nip')) {
                    setAttributes(document.querySelector("#f-wysylka-nip']"), { "minlength": "9", "pattern": "[0-9]+" })
                }
                if (document.querySelector('#i_regon')) {
                    document.querySelector('#i_regon').removeAttribute("required");
                }
            }
        })
    }
}


import { phoneCode } from "./phone-code";
if (document.querySelector('.phone-prefix')) {

    function selectPrefix(box) {
        let selectPhoneUl = box.querySelector("select");
        let selectPhoneBtn = box.querySelector(".i_telefon-btn");


        for (let i = 0; i < phoneCode.length; ++i) {

            let option = document.createElement("option");
            option.setAttribute("name", 'prefix');
            if (phoneCode[i].dial_code == selectPhoneBtn.innerHTML) {
                option.setAttribute("selected", "selected");
            }
            option.setAttribute("value", phoneCode[i].dial_code);
            option.innerHTML = phoneCode[i].name + " " + phoneCode[i].dial_code;
            selectPhoneUl.appendChild(option);


        }

        selectPhoneUl.addEventListener("change", function(e) {
            e.preventDefault();
            selectPhoneBtn.innerHTML = selectPhoneUl.value;
        })
    }

    let phonePrefix = document.querySelectorAll(".phone-prefix");

    for (let i = 0; i < phonePrefix.length; ++i) {
        selectPrefix(phonePrefix[i]);
    }
}